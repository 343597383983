// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$informed-decision-iot-primary: mat.define-palette(mat.$indigo-palette);
$informed-decision-iot-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$informed-decision-iot-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$informed-decision-iot-theme: mat.define-light-theme((
  color: (
    primary: $informed-decision-iot-primary,
    accent: $informed-decision-iot-accent,
    warn: $informed-decision-iot-warn,
  )
)); 

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($informed-decision-iot-theme);

/* You can add global styles to this file, and also import other style files */

html, body { 
  height: auto;
  max-width: 100%;
  overflow-x: hidden; 
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "~jsoneditor/dist/jsoneditor.min.css";

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bs-info,grey); 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--bs-primary,grey); 
}
.dropdown-toggle::after {
  display:none;
}

$size: 2px;
$border: 10px;
$green: #72ff7d;
$red: #ff4242;
$yellow: #ffda72;
$blue: #42e5ff;
$warning: #f79525;
.status {
  position: relative;
  display: inline-block;
  width: $size;
  height: $size;
  border-radius: 50%;
  margin: $border;

  &.online, &.online:before {
    background: $green;
  }
  
  &.offline, &.offline:before {
    background: $red;
  }
  
  &.invisible, &.invisible:before {
    background: $blue;
  }
  
  &.idle, &.idle:before {
    background: $yellow;
  }
  &.warning, &.idle:before {
    background: $warning;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -($border / 2);
    top: -($border / 2);
    width: $size + $border;
    height: $size + $border;
    border-radius: 50%;
   // animation: pulse 1.5s infinite ease-in;
  }
}

@keyframes pulse {
  from {
    transform: scale(0.5);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.stat-widget{
  width: 100%; 
  background-color: rgb(255, 255, 255); 
  color: rgb(74, 74, 74); 
  padding: 10px 14px; 
  text-align: center; 
  justify-content: center; 
  align-items: center; 
  flex-direction: column; 
  display: flex;
}
.heading-text{
  font-size: 17px;
}
.footer-text{
  font-size: 16px;
  height: 18px; 
  line-height: 18px;
}
.bottom-panel{
  display: flex; 
  font-size: 1.4rem; 
  margin: 0px;
}
.stats-pannel{
  height: 18px; 
  line-height: 18px; 
  font-weight: 500; 
  margin: 0.5rem 0px;
}
.top-pannel{
   height: 18px; 
   line-height: 18px;
}

.canvas-toolbar{
  height: 25px; 
  background-color: rgb(203, 33, 39); 
  color: rgb(255, 255, 255); 
  font-weight: 500; 
  color: rgb(255, 255, 255); 
  text-align: left;
  margin-bottom: 1em;
}
.toolbar-label{
  height: 25px; 
  line-height: 25px; 
  padding-left: 10px; 
  padding-right: 10px;
}
.text-bold{
  font-weight: bold;
}
.flow-stat-value{
  border: #000000 1px solid;
}