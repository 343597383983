
.bg-light-white {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
  
  .bg-blue {
    background-color: #005dc7 !important;
  }
  
  .bg-light-blue {
    background-color: rgba(0, 93, 199, 0.15) !important;
  }
  .bg-light-blue i,
  .bg-light-blue .icon {
    color: #005dc7;
  }
  
  .text-blue {
    color: #005dc7 !important;
  }
  
  .bg-indigo {
    background-color: #2b2b79 !important;
  }
  
  .bg-light-indigo {
    background-color: rgba(43, 43, 121, 0.15) !important;
  }
  .bg-light-indigo i,
  .bg-light-indigo .icon {
    color: #2b2b79;
  }
  
  .text-indigo {
    color: #2b2b79 !important;
  }
  
  .bg-purple {
    background-color: #695cb3 !important;
  }
  
  .bg-light-purple {
    background-color: rgba(105, 92, 179, 0.15) !important;
  }
  .bg-light-purple i,
  .bg-light-purple .icon {
    color: #695cb3;
  }
  
  .text-cyan {
    color: #695cb3 !important;
  }
  
  .bg-pink {
    background-color: #da2d6f !important;
  }
  
  .bg-light-pink {
    background-color: rgba(218, 45, 111, 0.15) !important;
  }
  .bg-light-pink i,
  .bg-light-pink .icon {
    color: #da2d6f;
  }
  
  .text-pink {
    color: #da2d6f !important;
  }
  
  .bg-red {
    background-color: #dd2255 !important;
  }
  
  .bg-light-red {
    background-color: rgba(221, 34, 85, 0.15) !important;
  }
  .bg-light-red i,
  .bg-light-red .icon {
    color: #dd2255;
  }
  
  .text-red {
    color: #dd2255 !important;
  }
  
  .bg-orange {
    background-color: #ee8946 !important;
  }
  
  .bg-light-orange {
    background-color: rgba(238, 137, 70, 0.15) !important;
  }
  .bg-light-orange i,
  .bg-light-orange .icon {
    color: #ee8946;
  }
  
  .text-orange {
    color: #ee8946 !important;
  }
  
  .bg-yellow {
    background-color: #ebbb1f !important;
  }
  
  .bg-light-yellow {
    background-color: rgba(235, 187, 31, 0.15) !important;
  }
  .bg-light-yellow i,
  .bg-light-yellow .icon {
    color: #ebbb1f;
  }
  
  .text-yellow {
    color: #ebbb1f !important;
  }
  
  .bg-green {
    background-color: #2e9c65 !important;
  }
  
  .bg-light-green {
    background-color: rgba(46, 156, 101, 0.15) !important;
  }
  .bg-light-green i,
  .bg-light-green .icon {
    color: #2e9c65;
  }
  
  .text-green {
    color: #2e9c65 !important;
  }
  
  .bg-teal {
    background-color: #65afaa !important;
  }
  
  .bg-light-teal {
    background-color: rgba(101, 175, 170, 0.15) !important;
  }
  .bg-light-teal i,
  .bg-light-teal .icon {
    color: #65afaa;
  }
  
  .text-teal {
    color: #65afaa !important;
  }
  
  .bg-cyan {
    background-color: #1cadc4 !important;
  }
  
  .bg-light-cyan {
    background-color: rgba(28, 173, 196, 0.15) !important;
  }
  .bg-light-cyan i,
  .bg-light-cyan .icon {
    color: #1cadc4;
  }