
.tempimage {
    margin: -15px 0px;
    position: relative;
    right: -10px;
  }
  
  .circle-small {
    height: 50px;
    width: 50px;
    position: relative;
  }
  .circle-small > .avatar, .circle-small > i {
    height: 34px;
    width: 34px;
    line-height: 34px;
    left: 8px;
    top: 8px;
    position: absolute;
    z-index: 1;
    display: block;
    vertical-align: middle;
  }
  
  .circle-medium {
    height: 100px;
    width: 100px;
    position: relative;
  }
  .circle-medium > .avatar, .circle-medium > i {
    height: 74px;
    width: 74px;
    line-height: 74px;
    left: 13px;
    top: 13px;
    position: absolute;
    z-index: 1;
    display: block;
    vertical-align: middle;
  }
  
  .smallchart {
    width: 100%;
    max-width: 100%;
    height: 95px;
    overflow-x: hidden;
  }
  
  .smallchart40 {
    width: 100%;
    max-width: 100%;
    height: 40px;
    overflow-x: hidden;
  }
  .smallchart40.cut-5 {
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px;
  }
  
  .smallchart50 {
    width: 100%;
    max-width: 100%;
    height: 50px;
    overflow-x: hidden;
  }
  .smallchart50.cut-5 {
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px;
  }
  
  .smallchart65 {
    width: 100%;
    max-width: 100%;
    height: 65px;
    overflow-x: hidden;
  }
  .smallchart65.cut-5 {
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px;
  }
  
  .smallchart90 {
    max-width: 100%;
    height: 90px;
    overflow-x: hidden;
  }
  .smallchart90 > canvas {
    height: 90px !important;
  }
  
  .smallchart80 {
    max-width: 100%;
    height: 80px;
    overflow-x: hidden;
  }
  .smallchart80 > canvas {
    height: 80px !important;
  }
  
  .mediumchart {
    max-width: 100%;
    height: 170px;
    overflow-x: hidden;
  }
  
  .doughnutchart {
    position: relative;
    width: 190px;
    height: 190px;
    margin: 0 auto;
    text-align: center;
  }
  .doughnutchart canvas {
    position: relative;
    z-index: 1;
  }
  .doughnutchart .countvalue {
    position: absolute;
    top: 33px;
    left: 33px;
    height: 124px;
    width: 124px;
    border-radius: 70px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    background-color: var(--adminux-theme-bg);
    z-index: 0;
  }
  .doughnutchart.medium {
    width: 174px;
    height: 174px;
  }
  .doughnutchart.medium .countvalue {
    top: 30px;
    left: 30px;
    height: 114px;
    width: 114px;
  }
  .doughnutchart.small {
    width: 100px;
    height: 100px;
  }
  .doughnutchart.small .countvalue {
    top: 10px;
    left: 10px;
    height: 80px;
    width: 80px;
    line-height: 20px;
  }
  .doughnutchart.small .countvalue p {
    width: 100%;
  }
  
  .bigchart250 {
    width: 100%;
    max-width: 100%;
    height: 250px;
    overflow-x: hidden;
  }
  .bigchart250.cut-5 {
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px;
  }
  
  .bigchart200 {
    width: 100%;
    max-width: 100%;
    height: 200px;
    overflow-x: hidden;
  }
  .bigchart200.cut-5 {
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px;
  }
  
  .semidoughnutchart {
    width: 100%;
    max-width: 240px;
    overflow: hidden;
    height: 120px;
    margin: 0 auto;
    position: relative;
  }
  .semidoughnutchart .expensedatasemidoughnut {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0px;
  }
  .semidoughnutchart canvas {
    margin-top: -23%;
    vertical-align: top;
  }
  
  .chartserver {
    height: 290px;
  }
  
  .jqvmap-label {
    z-index: 1;
  }
  
  .dragzonecard {
    min-height: 300px;
  }
  .dragzonecard > div {
    cursor: move;
    cursor: grab;
    cursor: -webkit-grab;
  }
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  