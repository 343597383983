/*
Template Name: Informed Decision IoT - Responsive 5 Admin Dashboard
Author: Sethala
Version: 2.0.0
Email: support@sethala.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";

