//
// avatar.scss (avatar thumbnail sizes)
//

// avatar height
.avatar-xs {
    height: 1.5rem;
    width: 1.5rem;
}

.avatar-sm {
    height: 2.25rem;
    width: 2.25rem;
}

.avatar {
    height: 3rem;
    width: 3rem;
}

.avatar-md {
    height: 3.5rem;
    width: 3.5rem;
}

.avatar-lg {
    height: 4.5rem;
    width: 4.5rem;
}

.avatar-xl {
    height: 6rem;
    width: 6rem;
}

.avatar-xxl {
    height: 7.5rem;
    width: 7.5rem;
}

.avatar-title {
    align-items: center;
    color: $white;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.avatar-group {
    padding-left: 12px;

    .avatar-group-item {
        margin: 0 0 10px -12px;
        display: inline-block;
        border: 2px solid $white;
        border-radius: 50%;
    }
}


.avatar > i {
    display: inline-block;
    vertical-align: middle;
  }
  .avatar > img {
    width: 100%;
    vertical-align: top;
  }
  .avatar.avatar-6 {
    line-height: 6px;
    height: 6px;
    width: 6px;
  }
  .avatar.avatar-10 {
    line-height: 8px;
    height: 10px;
    width: 10px;
  }
  .avatar.avatar-15 {
    line-height: 13px;
    height: 15px;
    width: 15px;
  }
  .avatar.avatar-20 {
    line-height: 16px;
    height: 20px;
    width: 20px;
  }
  .avatar.avatar-24 {
    line-height: 20px;
    height: 24px;
    width: 24px;
  }
  .avatar.avatar-30 {
    line-height: 28px;
    height: 30px;
    width: 30px;
  }
  .avatar.avatar-36 {
    line-height: 35px;
    height: 36px;
    width: 36px;
  }
  .avatar.avatar-40 {
    line-height: 40px;
    height: 40px;
    width: 40px;
  }
  .avatar.avatar-44 {
    line-height: 44px;
    height: 44px;
    width: 44px;
  }
  .avatar.avatar-50 {
    line-height: 50px;
    height: 50px;
    width: 50px;
  }
  .avatar.avatar-60 {
    line-height: 58px;
    height: 60px;
    width: 60px;
  }
  .avatar.avatar-70 {
    line-height: 68px;
    height: 70px;
    width: 70px;
  }
  .avatar.avatar-80 {
    line-height: 80px;
    height: 80px;
    width: 80px;
  }
  .avatar.avatar-90 {
    line-height: 90px;
    height: 90px;
    width: 90px;
  }
  .avatar.avatar-100 {
    line-height: 96px;
    height: 100px;
    width: 100px;
  }
  .avatar.avatar-110 {
    line-height: 110px;
    height: 110px;
    width: 110px;
  }
  .avatar.avatar-120 {
    line-height: 120px;
    height: 120px;
    width: 120px;
  }
  .avatar.avatar-130 {
    line-height: 130px;
    height: 130px;
    width: 130px;
  }
  .avatar.avatar-140 {
    line-height: 140px;
    height: 140px;
    width: 140px;
  }
  .avatar.avatar-150 {
    line-height: 150px;
    height: 150px;
    width: 150px;
  }
  .avatar.avatar-160 {
    line-height: 160px;
    height: 160px;
    width: 160px;
  }
  .avatar.avatar-170 {
    line-height: 170px;
    height: 170px;
    width: 170px;
  }
  .avatar.avatar-180 {
    line-height: 180px;
    height: 180px;
    width: 180px;
  }
  .avatar.avatar-190 {
    line-height: 190px;
    height: 190px;
    width: 190px;
  }
  .avatar.avatar-200 {
    line-height: 200px;
    height: 200px;
    width: 200px;
  }
.rounded {
    border-radius: var(--bs-border-radius) !important;
  }
  
  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .rounded-1 {
    border-radius: var(--bs-border-radius-sm) !important;
  }
  
  .rounded-2 {
    border-radius: var(--bs-border-radius) !important;
  }
  
  .rounded-3 {
    border-radius: var(--bs-border-radius-lg) !important;
  }
  
  .rounded-4 {
    border-radius: var(--bs-border-radius-xl) !important;
  }
  
  .rounded-5 {
    border-radius: var(--bs-border-radius-2xl) !important;
  }
  
  .rounded-circle {
    border-radius: 50% !important;
  }
  
  .rounded-pill {
    border-radius: var(--bs-border-radius-pill) !important;
  }
  
  .rounded-top {
    border-top-left-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important;
  }
  
  .rounded-end {
    border-top-right-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important;
  }
  
  .rounded-bottom {
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-bottom-left-radius: var(--bs-border-radius) !important;
  }
  
  .rounded-start {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-top-left-radius: var(--bs-border-radius) !important;
  }