//
// advanced-table.scss
//

table.advanced-table {
    border-collapse: collapse !important;
    margin-bottom: 15px !important;

    // Change icons view
    thead {
        th {
            position: relative;
            cursor: pointer;
            &.row_select {
                width: 45px;
                max-width: 55px !important;
            }
            &.sortable {
                &:before {
                    position: absolute;
                    right: 1em;
                    left: auto;
                    content: "\2191";
                    opacity: 0.3;
                    font-family: "Material Design Icons";
                    font-size: 1rem;
                }
                &:after {
                    position: absolute;
                    right: 0.5em;
                    left: auto;
                    content: "\2193";
                    opacity: 0.3;
                    font-family: "Material Design Icons";
                    font-size: 1rem;
                }
            }

            &.sorting_asc {
                &:before {
                    opacity: 1;
                }
            }
            &.sorting_desc {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    tbody {
        // Select table
        tr.selected,
        > tr > .selected {
            background-color: $gray-100;

            // td {
            //     border-color: $primary;
            // }
        }
        td {
            &:focus {
                outline: none !important;
            }
        }
    }
}
