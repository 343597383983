/*
Template Name: Informed Decision IoT - Responsive Bootstrap 5 Admin Dashboard
Author: Sethala
Version: 2.0.0
Website: https://sethala.com/
Contact: support@sethala.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "config/variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";
@import "custom/structure/horizontal-nav";
@import "custom/structure/two-column-menu";

//Components
@import "custom/components/avatar";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/print";
@import "custom/components/preloader";
@import "custom/components/advanced-table";
@import "custom/components/typeahead";
@import "custom/components/colors";
@import "custom/components/component-sizes";
//Pages
@import "custom/pages/authentication";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/email";
@import "custom/pages/pricing";
@import "custom/pages/profile";
@import "custom/pages/task";
@import "custom/pages/timeline";
@import "custom/pages/kanbanboard";
@import "custom/pages/file-manager.scss";
@import "custom/pages/chat";
@import "custom/pages/landing";

// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/calendar";
@import "custom/plugins/colorpicker";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/datatable";
@import "custom/plugins/form-wizard";
@import "custom/plugins/select2";
@import "custom/plugins/simplebar";
@import "custom/plugins/multiple-select";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/quilljs-editor";
@import "custom/plugins/flatpickr";
@import "custom/plugins/dropzone";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";
@import "custom/plugins/datepicker";
